import React, { useRef } from "react";
import PropTypes from "prop-types";
import {
  List,
  ListItem,
  CircularProgress,
  Box,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExerciseRecord from "./ExerciseRecord";
import { colors } from "../../styles";
import { usePaginationObserver } from "../../util/utilFunctions";
import ROUTINE_TYPES from "../../util/RoutineTypes";

const useStyles = makeStyles({
  listContainer: {
    overflowY: "auto",
    height: "88%",
    padding: 0,
  },
  exerciseListHeading: {
    border: `1px solid ${colors.divider_line_white}`,
    backgroundColor: colors.checkbox_grey,
    padding: "10px 25px",
  },
  ListHeadingText: {
    fontFamily: "DMSans, sans-serif",
    fontWeight: 500,
    fontSize: 14,
  },
});

function LoadingSpinner() {
  return (
    <ListItem
      key="loading spinner"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <CircularProgress size={20} />
    </ListItem>
  );
}

const ExerciseRecordWithRef = React.forwardRef((props, ref) => {
  return <ExerciseRecord lastExerciseRecordRef={ref} {...props} />;
});

const SupersetRecordWithRef = React.forwardRef((props, ref) => {
  return <ExerciseRecord lastSuperSetRecordRef={ref} {...props} />;
});

function ExerciseList(props) {
  const classes = useStyles(props);

  const {
    exerciseData,
    superSets,
    currentTab,
    isLoading,
    selectedIndex,
    setSelectedIndex,
    viewExerciseDetailsLink,
    viewSuperSetsLink,
    setSuperSetData,
    setSupersetPageNumber,
    hasMoreSuperset,
    isSuperSetLoading,
    viewCircuitLink,
    setCircuitsData,
    setIsExerciseDataRefresh,
  } = props;

  const favoriteCircuits = superSets?.filter(
    (circuit) =>
      circuit.routine_type === ROUTINE_TYPES.CIRCUIT && circuit?.is_favorite,
  );

  const nonFavoriteCircuits = superSets?.filter(
    (circuit) =>
      circuit.routine_type === ROUTINE_TYPES.CIRCUIT && !circuit?.is_favorite,
  );

  const favoriteSuperSets = superSets?.filter(
    (superset) =>
      superset.routine_type === ROUTINE_TYPES.SUPER_SET && superset?.is_favorite,
  );

  const nonFavoriteSuperSets = superSets?.filter(
    (superset) =>
      superset.routine_type === ROUTINE_TYPES.SUPER_SET &&
      !superset?.is_favorite,
  );

  const favoriteExercises = exerciseData?.filter(
    (exercise) => exercise?.is_favorite,
  );

  const nonFavoriteExercises = exerciseData?.filter(
    (exercise) => !exercise?.is_favorite,
  );

  const rootElement = useRef();
  const lastSuperSetRecordRef = usePaginationObserver({
    setPageNumber: setSupersetPageNumber,
    isLoading: isSuperSetLoading,
    hasMoreDataToLoad: hasMoreSuperset,
    rootElement: rootElement.current,
  });

  return (
    <List ref={rootElement} className={classes.listContainer}>
      {(favoriteCircuits?.length > 0 || nonFavoriteCircuits?.length > 0) && (
        <>
          {currentTab === "my-exercises" && (
            <Box className={classes.exerciseListHeading}>
              <Typography className={classes.ListHeadingText}>
                Circuit
              </Typography>
            </Box>
          )}

          {/* favorite supersets */}
          {favoriteCircuits?.map((exercise, index) => (
            <SupersetRecordWithRef
              ref={
                favoriteCircuits.length === index + 1
                  ? lastSuperSetRecordRef
                  : null
              }
              key={exercise.id}
              value={index}
              isSelected={selectedIndex === index}
              onChangeIndexSelected={setSelectedIndex}
              exercise={exercise}
              viewCircuitLink={viewCircuitLink}
              setCircuitsData={setCircuitsData}
              setIsExerciseDataRefresh={setIsExerciseDataRefresh}
            />
          ))}

          {/* no-favorite circuits */}
          {nonFavoriteCircuits?.map((exercise, index) => (
            <SupersetRecordWithRef
              ref={
                nonFavoriteCircuits.length === index + 1
                  ? lastSuperSetRecordRef
                  : null
              }
              key={exercise.id}
              value={index}
              isSelected={selectedIndex === index}
              onChangeIndexSelected={setSelectedIndex}
              exercise={exercise}
              viewCircuitLink={viewCircuitLink}
              setCircuitsData={setCircuitsData}
              setIsExerciseDataRefresh={setIsExerciseDataRefresh}
            />
          ))}
        </>
      )}
      {isSuperSetLoading && <LoadingSpinner />}

      {(favoriteSuperSets?.length > 0 || nonFavoriteSuperSets?.length > 0) && (
        <>
          {currentTab === "my-exercises" && (
            <Box className={classes.exerciseListHeading}>
              <Typography className={classes.ListHeadingText}>
                Super Sets
              </Typography>
            </Box>
          )}
          {/* favorite supersets */}
          {favoriteSuperSets?.map((exercise, index) => (
            <SupersetRecordWithRef
              ref={
                favoriteSuperSets.length === index + 1
                  ? lastSuperSetRecordRef
                  : null
              }
              key={exercise.id}
              value={index}
              isSelected={selectedIndex === index}
              onChangeIndexSelected={setSelectedIndex}
              exercise={exercise}
              viewSuperSetsLink={viewSuperSetsLink}
              setSuperSetData={setSuperSetData}
              setIsExerciseDataRefresh={setIsExerciseDataRefresh}
            />
          ))}

          {/* no-favorite supersets */}
          {nonFavoriteSuperSets?.map((exercise, index) => (
            <SupersetRecordWithRef
              ref={
                nonFavoriteSuperSets.length === index + 1
                  ? lastSuperSetRecordRef
                  : null
              }
              key={exercise.id}
              value={index}
              isSelected={selectedIndex === index}
              onChangeIndexSelected={setSelectedIndex}
              exercise={exercise}
              viewSuperSetsLink={viewSuperSetsLink}
              setSuperSetData={setSuperSetData}
              setIsExerciseDataRefresh={setIsExerciseDataRefresh}
            />
          ))}
        </>
      )}
      {isSuperSetLoading && <LoadingSpinner />}

      {(favoriteExercises.length > 0 || nonFavoriteExercises?.length > 0) && (
        <>
          {currentTab === "my-exercises" && (
            <Box className={classes.exerciseListHeading}>
              <Typography className={classes.ListHeadingText}>
                My Exercises
              </Typography>
            </Box>
          )}

          {/* favorite exercises */}
          {favoriteExercises.map((exercise, index) => (
            <ExerciseRecordWithRef
              ref={
                favoriteExercises.length === index + 1
                  ? props.lastExerciseRecordRef
                  : null
              }
              key={exercise.id}
              value={index}
              isSelected={selectedIndex === index}
              onChangeIndexSelected={setSelectedIndex}
              exercise={exercise}
              viewExerciseDetailsLink={viewExerciseDetailsLink}
              setIsExerciseDataRefresh={setIsExerciseDataRefresh}
            />
          ))}

          {/* no-favorite exercises */}
          {nonFavoriteExercises.map((exercise, index) => (
            <ExerciseRecordWithRef
              ref={
                nonFavoriteExercises.length === index + 1
                  ? props.lastExerciseRecordRef
                  : null
              }
              key={exercise.id}
              value={index}
              isSelected={selectedIndex === index}
              onChangeIndexSelected={setSelectedIndex}
              exercise={exercise}
              viewExerciseDetailsLink={viewExerciseDetailsLink}
              setIsExerciseDataRefresh={setIsExerciseDataRefresh}
            />
          ))}
        </>
      )}
      {isLoading && <LoadingSpinner />}
    </List>
  );
}

ExerciseList.propTypes = {
  exerciseData: PropTypes.array,
  superSets: PropTypes.array,
  currentTab: PropTypes.string,
  isLoading: PropTypes.bool,
  selectedIndex: PropTypes.number,
  setSelectedIndex: PropTypes.func,
  viewExerciseDetailsLink: PropTypes.func,
  setIsExerciseDataRefresh: PropTypes.func,
};

export default ExerciseList;
