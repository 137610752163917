import React, { useState } from 'react';
import { Box, Typography, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import querystring from 'query-string';
import { updateQuery } from '../../util/utilFunctions';

import DeleteDuplicateButton from '../Workouts/DeleteDuplicateButton';
import ConfirmDialog from '../Dialogs/ConfirmDialog';
import { duplicateProgram, clearProgram } from '../../reducers/selectedProgramReducer';
import nasmApi from '../../api/endpoints';
import { colors } from '../../styles';

const useStyles = makeStyles({
  root: props => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 25px',
    height: '80px',
    width: '100%',
    borderBottom: '1px solid #e6e6e6',
    cursor: 'pointer',
    backgroundColor: props.isSelected ? colors.baby_blue : colors.white,
    '&:hover': {
      backgroundColor: colors.baby_blue,
    },
    '&:active': {
      filter: 'brightness(88%)',
      backgroundColor: colors.white,
    },
  }),
  leftCont: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    height: '100%',
    width: '90%',
  },
  programName: {
    fontWeight: 500,
    fontSize: 17,
    color: colors.black,
  },
  difficultyWorkouts: {
    display: 'flex',
    alignItems: 'center',
    width: '220px',
  },
  difficultyWorkoutsText: {
    fontSize: '12px',
    color: colors.steel,
  },
  difficulty: {
    width: '150px',
  },
});

export const ProgramItemRef = React.forwardRef((props, ref) => {
  return <ProgramListItem lastElementRef={ref} {...props} />;
});

export function ProgramListItem (props) {
  const {
    programData,
    lastElementRef,
    getProgramUrl,
    setSelectedProgramIndex,
    index,
    isSelected,
    showDeleteButton,
    copyProgramLink,
    showDuplicateDeleteButtons,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const location = history.location;
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const classes = useStyles({ isSelected });

  const onDeleteProgram = () => {
    nasmApi.nasmPrograms.deleteProgram(programData.id).then(() => {
      // if the program being viewed in the program details panel will be deleted,
      // close the panel and only show the program list panel
      const query = querystring.parse(location.search, { parseBooleans: true, parseNumbers: true });
      if (location.pathname.includes('libraries/programs') && query.view !== 'programList') {
        const newQuery = updateQuery(location.search, { view: 'programList', programId: undefined });
        history.replace({ pathname: location.pathname, search: newQuery });
      }

      dispatch(clearProgram());
      window.location.reload();
    }).catch(() => {
      alert(`Could not delete ${programData.name}`);
    });
  };

  const onDuplicateProgram = () => {
    const isSingular = programData.workout_count === 1;
    const isConfirmed = window.confirm(
      `Copying this program will add ${programData.workout_count} workout${isSingular ? '' : 's'} to your library.`,
    );
    if (isConfirmed) {
      dispatch(duplicateProgram(programData.id)).then(() => {
        history.replace(copyProgramLink());
      }).catch(() => {
        alert('could not copy program.');
      });
    }
  };

  const DeleteDuplicateDropdown = () => {
    if (showDuplicateDeleteButtons) {
      return (
        <DeleteDuplicateButton
          showDeleteButton={showDeleteButton}
          onDeletePressed={() => setShowConfirmDialog(true)}
          onDuplicatePressed={onDuplicateProgram}
        />
      );
    }

    return null;
  };


  return (
    <>
      <ListItem className={classes.root} ref={lastElementRef}>
        <Link
          className={classes.leftCont}
          to={() => getProgramUrl(programData.id)}
          onClick={() => setSelectedProgramIndex(index)}
        >
          <Typography className={classes.programName}>{programData.name}</Typography>
          <Box className={classes.difficultyWorkouts}>
            <Typography className={`${classes.difficultyWorkoutsText} ${classes.difficulty}`}>
              {programData.program_category.label}
            </Typography>
            <Typography className={classes.difficultyWorkoutsText}>
              {`${programData.workout_count} workout${programData.workout_count !== 1 ? 's' : ''}`}
            </Typography>
          </Box>
        </Link>
        <DeleteDuplicateDropdown />
      </ListItem>
      <ConfirmDialog
        title='Delete Program'
        description={`Deleting ${programData.name ? `the program "${programData.name}"` : 'this program'} ` +
        'will remove it from your library forever. Are you sure? '}
        actionButtonTitle='Delete'
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        handleConfirmAction={onDeleteProgram}
      />
    </>
  );
}
