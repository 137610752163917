import React, { useState, useEffect } from 'react';
import { Box, Typography, IconButton } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import moment from 'moment';

import {
  selectProgram,
  saveProgram,
  editProgramName,
  editProgramCategory,
  duplicateProgram,
  setWorkoutDays,
  clearProgram,
  removeWorkout,
  selectScheduledProgram,
} from '../../reducers/selectedProgramReducer';

import { selectWorkout, clearWorkout } from '../../reducers/selectedWorkoutReducer';

import { programContexts } from '../../reducers/programContextReducer';

import SlidingFlexTransition from '../Transitions/SlidingFlexTransition';
import FadeTransition from '../Transitions/FadeTransition';
import Panel from './Panel';
import ScheduleHeader from '../Schedule/ScheduleHeader';
import { ActionButton, OvalButton, MacaroniButton } from '../Buttons';
import {
  ProgramNameField,
  ProgramCategoryDropdown,
  ProgramCategoryText,
  AddWorkoutButton,
} from '../Programs/ProgramDetailsFields';
import nasmApi from '../../api/endpoints/';
import querystring from 'query-string';

import { colors } from '../../styles';

import trashIcon from '../../resources/icon-trash-dark.svg';
import ConfirmDialog from '../Dialogs/ConfirmDialog';

const useStyles = makeStyles({
  programDetailsPanel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
  },
  root: {
    padding: '20px',
  },
  headerBtnCont: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeButton: {
    marginLeft: '14px',
  },
  programNameHeader: {
    color: colors.steel,
    fontSize: '14px',
    fontWeight: 'bold',
  },
  programNameCont: {
    marginTop: '20px',
  },
  fixedProgramCategoryCont: {
    display: 'flex',
    alignItems: 'center',
    width: '300px',
    margin: '10px 20px',
  },
  categoryHeader: {
    marginRight: '5px',
    fontWeight: 'bold',
  },
  middleSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '16px',
    width: '470px',
  },
  workoutList: {
    borderTop: '1px solid #e6e6e6',
    height: '85%',
  },
  workoutItemBorder: {
    borderBottom: '1px solid #e6e6e6',
    backgroundColor: colors.white,
  },
  workoutItem: {
    padding: ' 20px 30px',
    position: 'relative',
    '&:hover': {
      backgroundColor: colors.baby_blue,
    },
    '&:active': {
      backgroundColor: colors.white,
      filter: 'brightness(88%)',
    },
  },
  workoutItemMyPrograms: {
    padding: ' 20px 30px',
    position: 'relative',
    '&:hover': {
      backgroundColor: colors.baby_blue,
      '& > div #workout-dur-elem': {
        display: 'none',
      },
      '& > #workout-delete-icon': {
        display: 'flex',
      },
    },
    '&:active': {
      backgroundColor: colors.white,
      filter: 'brightness(88%)',
    },
  },
  deleteIconContainer: {
    position: 'absolute',
    right: 21,
    top: 0,
    height: '100%',
    display: 'none',
    zIndex: 100000,
    alignItems: 'center',
  },
  workoutName: {
    fontSize: 17,
    fontWeight: 500,
    color: colors.black,
  },
  workoutStatsCont: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  exerciseCount: {
    fontSize: '12px',
    color: colors.steel,
  },
  duration: {
    fontSize: '12px',
    color: colors.black,
  },
  bubbleCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '16px',
  },
  skeletonBubble: {
    borderRadius: '5.5px',
  },
});

function WorkoutItem ({ workout, getWorkoutUrlParams, setWorkoutToDelete, isMyPrograms, updateButtonVisibilityFlags }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const programContext = useSelector(state => state.programContext.context);

  const location = useLocation();
  const query = querystring.parse(location.search);
  const workoutId = query?.workoutId;

  const { pathname, search } = getWorkoutUrlParams(workout?.id);

  const currentUser = JSON.parse(localStorage.getItem('AUTH_TOKEN'));

  const handleDeleteWorkout = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (currentUser?.cc_manager || !isMyPrograms) {
      // We should prevent this method if user is not viewing their programs.
      return;
    }

    setWorkoutToDelete(workout);
  };

  const DeleteWorkoutButton = ({ handleDeleteWorkout, isMyPrograms }) => {
    if (currentUser?.cc_manager || !isMyPrograms) {
      return null;
    }

    return (
      <Box id='workout-delete-icon' className={classes.deleteIconContainer}>
        <IconButton onClick={handleDeleteWorkout}>
          <img src={trashIcon} alt='Delete workout' />
        </IconButton>
      </Box>
    );
  };

  /* Set to scheduled workout name when context is RESCHEDULING */
  const workoutName = [programContexts.LIBRARY, programContexts.SCHEDULING].includes(programContext)  ?
    workout.name : workout.workout_name;

  return (
    <Box className={classes.workoutItemBorder}>
      <Link
        onClick={() => {
          if(programContext === programContexts.RESCHEDULING && updateButtonVisibilityFlags) {
            // WorkoutSectionPanel buttons
            updateButtonVisibilityFlags({
              isCopyButtonVisible: false,
              isSaveButtonVisible: false,
              isAddExercisesButtonVisible: true,
              isAddButtonVisible: false,
            });
            if (workout?.id !== workoutId) {              
              dispatch(selectWorkout(workout, true, false));
            }
          } else if(programContext === programContexts.LIBRARY || programContext === programContexts.SCHEDULING) {
            if (workout?.id !== workoutId) {              
              dispatch(selectWorkout(workout, true, false));
            }
          }

        }}
        to={{
          pathname: pathname,
          search: search,
        }}
      >
        {/* Conditionally set the classname based on tab context */}
        <Box className={isMyPrograms ? classes.workoutItemMyPrograms : classes.workoutItem}>
          <Typography className={classes.workoutName}>{workoutName}</Typography>
          <Box className={classes.workoutStatsCont}>
            <Typography className={classes.exerciseCount}>
              {workout.exercise_count} exercise{workout.exercise_count !== 1 ? 's' : ''}
            </Typography>
            <Typography id='workout-dur-elem' className={classes.duration}>
              {Math.ceil(workout.total_dur_seconds / 60)} min
            </Typography>
          </Box>
          {/* Will only exist if viewing "My Programs" */}
          <DeleteWorkoutButton
            isMyPrograms={isMyPrograms}
            handleDeleteWorkout={handleDeleteWorkout}
          />
        </Box>
      </Link>
    </Box>
  );
}

const DraggableWorkoutItem = React.forwardRef((props, ref) => {
  const {
    workout,
    getWorkoutUrlParams,
    index,
    isEditable,
    setWorkoutToDelete,
    isMyPrograms,
    updateButtonVisibilityFlags,
  } = props;

  return (
    <Draggable draggableId={workout.key} key={workout.key} index={index}>
      {(provided, snapshot) => {
        return (
          <Box
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <WorkoutItem
              key={workout.key}
              workout={workout}
              getWorkoutUrlParams={getWorkoutUrlParams}
              isDragging={snapshot.isDragging}
              isEditable={isEditable}
              setWorkoutToDelete={setWorkoutToDelete}
              isMyPrograms={isMyPrograms}
              updateButtonVisibilityFlags={updateButtonVisibilityFlags}
            />
          </Box>
        );
      }}
    </Draggable>
  );
});

function WorkoutPlaceholder () {
  const classes = useStyles();
  return (
    <Box className={classes.workoutItemBorder}>
      <Box className={classes.workoutItem}>
        <Skeleton animation={false} className={classes.skeletonBubble} variant='rect' width={230} height={10} />
        <Box className={classes.bubbleCont}>
          <Skeleton animation={false} className={classes.skeletonBubble} variant='rect' width={80} height={10} />
          <Skeleton animation={false} className={classes.skeletonBubble} variant='rect' width={80} height={10} />
        </Box>
      </Box>
    </Box>
  );
}

const DroppableWorkoutList = React.forwardRef((props, ref) => {
  const {
    program,
    workouts,
    getWorkoutUrlParams,
    setWorkoutToDelete,
    isMyPrograms,
    updateButtonVisibilityFlags,
  } = props;
  const classes = useStyles();
  return (
    <Droppable droppableId='Program-Workouts'>
      {provided => {
        return (
          <Box
            className={classes.workoutList}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {
              (program && program.workouts.length === 0) &&
                <WorkoutPlaceholder />
            }
            {program && program.workouts.length > 0 && Object.values(workouts).map((workout, index) => {
              return (
                <DraggableWorkoutItem
                  key={workout.key}
                  index={index}
                  workout={workout}
                  getWorkoutUrlParams={getWorkoutUrlParams}
                  isEditable={program.editable}
                  setWorkoutToDelete={setWorkoutToDelete}
                  isMyPrograms={isMyPrograms}
                  updateButtonVisibilityFlags={updateButtonVisibilityFlags}
                />);
            })}
            {provided.placeholder}
          </Box>
        );
      }}
    </Droppable>
  );
});

function WorkoutList (props) {
  const { program, workouts, getWorkoutUrlParams, setWorkoutToDelete, isMyPrograms } = props;
  const classes = useStyles();
  return (
    <Box className={classes.workoutList}>
      {
        (program && program.workouts.length === 0) &&
          <WorkoutPlaceholder />
      }
      {Object.values(workouts).map((workout, index) => {
        return (
          <WorkoutItem
            key={index}
            workout={workout}
            getWorkoutUrlParams={getWorkoutUrlParams}
            setWorkoutToDelete={setWorkoutToDelete}
            isMyPrograms={isMyPrograms}
          />);
      })}
    </Box>
  );
}

export default function ProgramDetailsPanel (props) {
  const classes = useStyles(props);
  const location = useLocation();

  const {
    visible,
    programListKey,
    addWorkoutKey,
    currentView,
    programId,
    resetProgramIndex,
    getWorkoutUrlParams,
    copyProgramLink,
    droppable,
    onClickSchedule,
    backLink,
    updateButtonVisibilityFlags,
  } = props;

  const [programCategories, setProgramCategories] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSavePending, setSavePending] = useState(false);
  const [workoutToDelete, setWorkoutToDelete] = useState(null);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const workouts = useSelector(state => state.selectedProgram.entities?.workouts ?? {});
  const programData = useSelector(state => state.selectedProgram);
  const selectedWorkoutData = useSelector(state => state.selectedWorkout);
  const selectedWorkout = selectedWorkoutData.workout;
  const program = programData.program;
  const selectedProgramId = program?.id;

  const isDuplicate = useSelector(state => state.selectedProgram.duplicate);
  const programContext = useSelector(state => state.programContext.context);

  const isMyPrograms = location.pathname.includes('my-programs');
  const isScheduling = programContext === programContexts.SCHEDULING;
  const isRescheduling = programContext === programContexts.RESCHEDULING;
  const isLibraryContext = programContext === programContexts.LIBRARY;

  useEffect(() => {
    const searchParams = querystring.parse(location.search, { parseBooleans: true });
    const isNew = searchParams.programId === 'new';
    if (programId && programId !== selectedProgramId && !isNew && !isRescheduling) {
      dispatch(selectProgram({ id: programId, scheduleId: location.state?.scheduleId ?? null }));
    }
    // handling the case for duplicating programs
    if (programId && !searchParams.programId) {
      if (isRescheduling) {
        dispatch(selectScheduledProgram({ id: programId, scheduleId: searchParams.scheduleId ?? null }));
      } else {
        dispatch(selectProgram({ id: programId }));
      }
    }
  }, [programId, selectedProgramId, dispatch, location, isRescheduling]);

  useEffect(() => {
    nasmApi.nasmPrograms.getProgramCategories().then((categories) => {
      setProgramCategories(categories?.result);
    });
  }, [programId, location]);

  const toProgramList = () => {
    return { search: updateQuery(location.search, { view: programListKey, programId: undefined }) };
  };

  const updateWorkoutDays = () => {
    Object.values(workouts).forEach((workout) => {
      // eslint-disable-next-line camelcase
      const days = workout?.days_of_week?.map(day => day.toString());
      dispatch(setWorkoutDays({ workoutKey: workout.key, days }));
    });
  };

  const onSaveProgram = () => {
    dispatch(saveProgram()).then(() => {
      if (isRescheduling) {
        dispatch(clearProgram());
      }
    });
  };

  const onEditProgramName = (name) => {
    dispatch(editProgramName(name));
  };

  const onBlurProgramName = () => {
    if (program.program_category && program.name) {
      if (isRescheduling) {
        updateWorkoutDays();
      }
      dispatch(saveProgram());
    }
  };

  const onClickDropdownMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const onClickDropdownItem = (category) => {
    const programCategory = programCategories.find((c) => c.label === category);
    dispatch(editProgramCategory({ ...program?.program_category, label: category, id: programCategory.id }));
    setAnchorEl(null);
  };

  const onCloseDropdownMenu = () => {
    if (program?.program_category && program?.name && programData?.programChanged) {
      if (isRescheduling) {
        updateWorkoutDays();
      }
      onSaveProgram();
    }
    setAnchorEl(null);
  };

  const fillProgramInfo = () => {
    if (!program?.name) {
      dispatch(editProgramName(`New Program ${moment().format('MM/DD/YYYY hh:mm:ss')}`));
    }
    if (!program?.program_category) {
      const generalFitness = programCategories.find((c) => c?.label === 'General Fitness');
      dispatch(editProgramCategory({ label: generalFitness?.label, id: generalFitness?.id }));
    }
  };

  const onDuplicateProgram = () => {
    const isSingular = program.workouts.length === 1;
    const isConfirmed = window.confirm(
     `Copying this program will add ${program.workouts.length} workout${isSingular ? '' : 's'} to your library.`,
    );
    if (isConfirmed) {
      dispatch(duplicateProgram(program.id)).then(() => {
        history.replace(copyProgramLink());
      }).catch(() => {
        alert('could not copy program.');
      });
    }
  };

  const onPressSave = () => {
    setSavePending(true);
    dispatch(saveProgram()).then(() => {
      alert('Successfully saved program!');
      history.replace('/libraries/programs/my-programs', { programDuplicateName: program.name });
      if (isRescheduling) {
        dispatch(clearProgram());
        dispatch(clearWorkout());
      }
    }).catch(() => {
      alert('Could not save program');
    }).finally(() => setSavePending(false));
  };

  const onCloseWorkoutDeleteDialog = () => {
    setWorkoutToDelete(null);
  };

  const OnConfirmRemoveWorkout = async () => {
    dispatch(removeWorkout(workoutToDelete));
    try {
      await dispatch(saveProgram());
      if (isLibraryContext) {
        const query = querystring.parse(location.search, { parseBooleans: true, parseNumbers: true });
        // Check if we are viewing the details of the workout to be removed currently.
        // If so, trigger logic to close the workoutDetails panel and transition back to the programDetails
        if (query.view === 'workoutDetails' && selectedWorkout.key === workoutToDelete.key) {
          const newQuery = updateQuery(location.search, { view: 'programDetails', workoutId: undefined });
          history.replace({ pathname: location.pathname, search: newQuery });
          dispatch(clearWorkout());
        }
      }
    } catch (err) {
      setIsErrorDialogOpen(true);
      console.error(err);
    } finally {
      onCloseWorkoutDeleteDialog();
    }
  };

  const onCloseWorkoutDeleteErrorDialog = () => {
    setIsErrorDialogOpen(false);
    // Refresh current page
    history.go(0);
  };

  return (
    <SlidingFlexTransition visible={visible}>
      <Panel height={800}>
        <FadeTransition visible={visible}>
          <ConfirmDialog
            title='Remove Workout'
            description={
              `You're about to remove the workout "${workoutToDelete?.name}". 
              You can add this workout again anytime.`
            }
            actionButtonTitle='Remove'
            open={!!workoutToDelete}
            onClose={onCloseWorkoutDeleteDialog}
            handleConfirmAction={OnConfirmRemoveWorkout}
            loading={programData.loading}
          />
          <ConfirmDialog
            title='Oops, there seems to be a problem'
            description={'We couldn\'t update your program at this time. Please refresh the page and try again.'}
            actionButtonTitle='Ok'
            open={isErrorDialogOpen}
            onClose={onCloseWorkoutDeleteErrorDialog}
            handleConfirmAction={onCloseWorkoutDeleteErrorDialog}
          />
          <Box className={classes.programDetailsPanel}>
            <Box className={classes.root}>
              <Box className={classes.headerBtnCont}>
                {location.pathname.includes('nasm-programs') && !isDuplicate && !isScheduling &&
                  <MacaroniButton onClick={onDuplicateProgram}>Copy</MacaroniButton>}
                {
                  isDuplicate &&
                    <ActionButton loading={isSavePending} label='Save' onClick={onPressSave} />
                }
                {
                  (isScheduling || (isRescheduling && programData.programChanged)) &&
                    <MacaroniButton width='95px' onClick={onClickSchedule}>Schedule</MacaroniButton>
                }
                <Link
                  to={isRescheduling ? backLink : toProgramList()}
                  onClick={resetProgramIndex}
                >
                  <OvalButton className={classes.closeButton}>Close</OvalButton>
                </Link>
              </Box>
              {
                isScheduling &&
                  <ScheduleHeader>
                    Program Details.
                  </ScheduleHeader>
              }
              <Typography className={classes.programNameHeader}>Program Name</Typography>
              <Box className={classes.programNameCont}>
                <ProgramNameField
                  isMyPrograms={isMyPrograms}
                  isScheduling={isScheduling}
                  isDuplicate={isDuplicate}
                  isRescheduling={isRescheduling}
                  program={program}
                  programName={program ? program.name : ''}
                  programChanged={programData.programChanged}
                  onChange={onEditProgramName}
                  onBlurProgramName={onBlurProgramName}
                />
              </Box>
              <Box>
                <Box className={classes.middleSection}>
                  <ProgramCategoryDropdown
                    isMyPrograms={isMyPrograms}
                    isScheduling={isScheduling}
                    isRescheduling={isRescheduling}
                    programCategories={programCategories}
                    anchorEl={anchorEl}
                    program={program}
                    onClickDropdownMenu={onClickDropdownMenu}
                    onClickDropdownItem={onClickDropdownItem}
                    onCloseDropdownMenu={onCloseDropdownMenu}
                  />
                  <AddWorkoutButton
                    program={program}
                    isMyPrograms={isMyPrograms}
                    isScheduling={isScheduling}
                    isRescheduling={isRescheduling}
                    currentView={currentView}
                    location={location}
                    addWorkoutKey={addWorkoutKey}
                    fillProgramInfo={fillProgramInfo}
                  />
                </Box>
              </Box>
            </Box>
            <ProgramCategoryText
              isMyPrograms={isMyPrograms}
              isScheduling={isScheduling}
              isRescheduling={isRescheduling}
              program={program}
            />
            <Box style={{ overflowY: 'auto', height: '85%' }}>
            {
              (isMyPrograms || isRescheduling) && droppable &&
                <DroppableWorkoutList
                  program={program}
                  workouts={workouts}
                  getWorkoutUrlParams={getWorkoutUrlParams}
                  setWorkoutToDelete={setWorkoutToDelete}
                  isMyPrograms={isMyPrograms}
                  updateButtonVisibilityFlags={updateButtonVisibilityFlags}
                />
            }
            {
              ((!isMyPrograms && !isRescheduling) || !droppable) &&
                <WorkoutList
                  program={program}
                  workouts={workouts}
                  getWorkoutUrlParams={getWorkoutUrlParams}
                  isMyPrograms={isMyPrograms}
                />
            }
            </Box>
          </Box>
        </FadeTransition>
      </Panel>
    </SlidingFlexTransition>
  );
}

// Helper functions
function updateQuery (search, next) {
  const prev = querystring.parse(search, { parseBooleans: true });
  return querystring.stringify({
    ...prev,
    ...next,
  });
}
