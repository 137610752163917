import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Box, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useGroupsContext } from "../../contexts/GroupsContext";
import { MyGroupsPageHeader } from "../../components/PageHeaders";
import { FloatingCircleButton } from "../../components/Buttons";
import {
  deselectGroup,
  selectGroup,
} from "../../reducers/selectedGroupReducer";
import { deselectClient } from "../../reducers/selectedClientReducer";
import HighlightSearchBar from "../../components/Inputs/HighlightSearchBar";
import EmptySection from "../../components/EmptyStates/EmptySection";
import ftuClient from "../../resources/image-ftu-add-client.png";
import Panel from "../../components/Panels/Panel";
import GroupView from "./GroupView";
import GroupListSkeleton from "../../components/LoadingStates/GroupListSkeleton";
import AddNewGroup from "../../components/Dialogs/AddNewGroup";
import { colors } from "../../styles";
import { clearWorkout } from "../../reducers/selectedWorkoutReducer";
import { clearProgram } from "../../reducers/selectedProgramReducer";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: colors.white,
    height: "100%",
    paddingLeft: 200,
    paddingRight: 200,
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  topSection: {
    paddingTop: "30px",
    flex: 1,
  },
});

export default function MyGroupsList() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedProfile = useSelector(state => state?.clubConnect?.selectedProfile);
  const [addNewGroupOpen, setAddNewGroupOpen] = useState(false);
  const [isPlusButtonDisabled, setPlusButtonDisabled] = useState(false);
  const [searchText, setSearchText] = useState("");
  const {
    loading,
    error,
    allGroups,
    filteredGroups: groups,
    fetchGroups,
    findGroups,
  } = useGroupsContext();

  // Search for groups by name
  useEffect(() => {
    findGroups(searchText);
  }, [findGroups, searchText]);

  // Fetch all groups of the trainer
  useEffect(() => {
    fetchGroups(selectedProfile?.ClubId, selectedProfile?.Locations?.Id);
    // Refresh listener
    window.addEventListener("refreshGroups", () => {
      fetchGroups(selectedProfile?.ClubId, selectedProfile?.Locations?.Id);
    });
    return () => {
      window.removeEventListener("refreshGroups");
    };
  }, [fetchGroups, selectedProfile]);

  useEffect(() => {
    dispatch(deselectClient());
    dispatch(deselectGroup());
    dispatch(clearProgram());
    dispatch(clearWorkout());
  }, [dispatch]);

  const onClickAdd = () => {
    setAddNewGroupOpen(true);
    setPlusButtonDisabled(true);
  };

  const onClearSearch = () => {
    setSearchText("");
  };

  const onChangeSearchText = (e) => {
    setSearchText(e.target.value);
  };

  const onClickGroup = (group, isEdit) => {
    dispatch(selectGroup(group ?? {}));
    if (isEdit) {
      onClickAdd();
      return;
    }
    history.push(
      `/groups/my-groups/${group.id
        .toLowerCase()
        .replaceAll(" ", "")}/dashboard`,
    );
  };

  const onCloseAddGroup = () => {
    setAddNewGroupOpen(false);
    setPlusButtonDisabled(false);
    dispatch(deselectGroup());
  };

  const areGroupsLoadedAndAdded = !loading && !error && allGroups.length > 0;
  const isInitialLoading = loading && !error && allGroups.length === 0;
  const isFirstTimeUserExp = !loading && !error && allGroups.length === 0;
  const areGroupResultsFound = !loading && !error && groups.length > 0;
  const areNoSearchResultsFound =
    !loading && !error && groups.length === 0 && searchText !== "";

  return (
    <Box>
      <GroupsPageHeader loading={loading} />
      <FloatingCircleButton
        disabled={isPlusButtonDisabled}
        onClick={onClickAdd}
      />
      <Box className={classes.container}>
        <Box className={classes.root}>
          <Container maxWidth={false} className={classes.topSection}>
            <GroupListSkeleton visible={isInitialLoading} />
            <AddNewGroup open={addNewGroupOpen} onClose={onCloseAddGroup} />
            <SearchAndFiltersBar
              visible={areGroupsLoadedAndAdded}
              searchText={searchText}
              onClearSearch={onClearSearch}
              onChangeSearchText={onChangeSearchText}
            />
            <GroupListContents
              visible={areGroupResultsFound}
              groups={groups}
              onClickGroup={onClickGroup}
            />
            <NoSearchResults visible={areNoSearchResultsFound} />
            <EmptySectionPanel
              visible={isFirstTimeUserExp}
              pathname={history.location.pathname}
              onClickAdd={onClickAdd}
            />
          </Container>
        </Box>
      </Box>
    </Box>
  );
}

const useSearchAndFilterBarStyles = makeStyles({
  searchFilterCont: {
    display: "flex",
    width: "430px",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

/**
 * @return {JSX|null}
 */
function SearchAndFiltersBar(props) {
  const { visible, searchText, onClearSearch, onChangeSearchText } = props;

  const classes = useSearchAndFilterBarStyles();

  if (!visible) {
    return null;
  }

  return (
    <Box className={classes.searchFilterCont}>
      <HighlightSearchBar
        isClearable={searchText.length > 0}
        onClickClear={onClearSearch}
        value={searchText}
        onChange={onChangeSearchText}
      />
    </Box>
  );
}

const useGroupListContentsStyle = makeStyles({
  groupListCont: {
    display: "flex",
    columnGap: 60,
    flexWrap: "wrap",
    marginTop: "20px",
    overflowY: "auto",
  },
});

/**
 * @return {JSX|null}
 */
function GroupListContents(props) {
  const { visible, groups = [], onClickGroup } = props;
  const classes = useGroupListContentsStyle();

  if (!visible) {
    return null;
  }

  return (
    <Box className={classes.groupListCont}>
      {groups.map((group, index) => {
        return (
          <GroupView
            group={group}
            key={index}
            onClick={() => onClickGroup(group)}
            onClickEdit={() => onClickGroup(group, true)}
          />
        );
      })}
    </Box>
  );
}

const useNoSearchResultsStyles = makeStyles({
  noSearchResults: {
    width: "100%",
    textAlign: "center",
    marginBottom: 20,
  },
});

/**
 * @return {JSX|null}
 */
function NoSearchResults(props) {
  const { visible } = props;
  const classes = useNoSearchResultsStyles();

  if (!visible) {
    return null;
  }

  return (
    <Container className={classes.noSearchResults}>
      <Typography>No Search Results Found.</Typography>
    </Container>
  );
}

/**
 * @return {JSX|null}
 */
function EmptySectionPanel(props) {
  const { visible, onClickAdd, pathname } = props;

  if (!visible) {
    return null;
  }

  return (
    <Panel>
      <EmptySection
        ftuImg={ftuClient}
        emptyTitle="Create a new group"
        emptyDescription={
          "Chat and assign workouts, programs, and exercises to a group of clients."
        }
        onClickPlus={onClickAdd}
        to={pathname}
      />
    </Panel>
  );
}

function GroupsPageHeader(props) {
  const { loading } = props;

  if (loading) {
    return <MyGroupsPageHeader />;
  }

  return <MyGroupsPageHeader />;
}
