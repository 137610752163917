import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  cardContainer: {
    boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.15)',
    border: props => props.border || 'solid 1px rgba(182, 189, 195, 0.2)',
    borderRadius: props => props.borderRadius || 6,
    backgroundColor: 'white',
    width: props => props.width || 'auto',
    height: props => props.height || 'auto',
    backgroundImage: props => props.backgroundImage ? `url(${props.backgroundImage})` : 'auto',
    backgroundSize: 'cover',
  },
});

function CardContainer (props) {
  const classes = useStyles(props);

  return (
    <Box className={classes.cardContainer}>
      {props.children}
    </Box>
  );
}

CardContainer.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CardContainer;