import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { styled, makeStyles } from '@material-ui/core/styles';
import CardContainer from '../CardContainer';

import { colors } from '../../../styles';
import {OvalButton} from '../../Buttons';

const Header = styled(Box)({
  backgroundColor: colors.lightBlue,
  borderTopLeftRadius: 10,
  borderBottomRightRadius: 5,
  borderBottomLeftRadius: 5,
  minHeight: 40,
  width: '85%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
});

const useStyles = makeStyles({
  headerText: {
    color: colors.white,
    marginLeft: 18,
    fontSize: 14,
    fontWeight: 'bold',
    overflowWrap: 'break-word',
  },
  titleText: props => ({
    color: colors.white,
    alignText: 'center',
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 1.29,
    margin: props.programNameExists ? '63px 30px 30px 30px' : '83px 30px 30px 30px',
    overflowWrap: 'break-word',
  }),
  scheduleInfoContainer: {
    marginLeft: 30,
  },
  scheduleInfo: {
    display: 'grid',
    gridTemplateColumns: '1fr 10px 2fr 10px',
    columnGap: 18,
    color: colors.white,
  },
});

function SchedulePreview (props) {
  const {
    programName,
    scheduleName,
    programCategoryName,
    minutesDuration,
    backgroundImage,
    isPastSchedule,
    onSelectSchedule,
  } = props;

  const classes = useStyles({ programNameExists: !!programName });
  const currentUser = JSON.parse(localStorage.getItem('AUTH_TOKEN'));
  return (
    <CardContainer
      width={380}
      height={314}
      borderRadius={14}
      border={'0'}
      backgroundImage={backgroundImage}
    >
      {!!programName && <Header>
        <Typography className={classes.headerText}>
          {programName}
        </Typography>
      </Header>}
      <Typography className={classes.titleText}>
        {scheduleName}
      </Typography>
      <div className={classes.scheduleInfoContainer}>
        <div className={classes.scheduleInfo}>
          <Typography>
            {`${minutesDuration} ${minutesDuration === 0 || minutesDuration > 1 ? 'minutes' : 'minute'}`}
          </Typography>
          <Typography style={{placeSelf: 'center'}}>|</Typography>
          <Typography>
            {programCategoryName}
          </Typography>
        </div>
      </div>
      {!currentUser?.cc_manager ? (
      <EditButton
        isPastSchedule={isPastSchedule}
        onClick={onSelectSchedule}
        />) : null }
    </CardContainer>
  );
}

const useEditButtonStyles = makeStyles({
  editButton: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '30px 26px',
  },
  editText: {
    fontWeight: 'bold',
    fontSize: 19,
    textAlign: 'center',
  },
  opacityOverlay: {
    position: 'absolute',
    width: 85,
    height: 38,
    borderRadius: 19,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'white',
      opacity: 0.06,
    },
  },
});

/**
 * @return {null|JSX}
 */
function EditButton (props) {
  const { isPastSchedule, onClick } = props;
  const classes = useEditButtonStyles();
  const buttonRef = useRef();

  if(isPastSchedule) {
    return null;
  }

  return (
    <div className={classes.editButton}>
        <OvalButton
          ref={buttonRef}
          backgroundcolor={'transparent'}
          color={'white'}
          border={'solid 2px white'}
          width={85}
          onClick={onClick}
        >
          <Typography className={classes.editText}>
            Edit
          </Typography>
        </OvalButton>
      {/* Overlay blocks button from firing on-click listener. This allows the button to trigger the click by ref */}
      <div
        className={classes.opacityOverlay}
        onClick={() => {
          if(!!buttonRef.current) {
            buttonRef.current.click();
          }
        }}
      />
    </div>
  );
}

SchedulePreview.propTypes = {
  /**
   * Program Category Image to display to the trainer.
   * If viewing from Storybook, use the programCategoryImageType parameter to
   * change which program category image to render.
   */
  backgroundImage: PropTypes.string.isRequired,
  programName: PropTypes.string,
  scheduleName: PropTypes.string,
  programCategoryName: PropTypes.string,
  minutesDuration: PropTypes.number,
  isPastSchedule: PropTypes.bool,
};

export default SchedulePreview;