import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled, withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { ReactComponent as ExpandIcon } from '../../resources/btn-arrow-expand.svg';

import Milestone from './Milestone';
import { colors } from '../../styles';

const AccordionHeader = styled(Typography)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  color: colors.steel,
  fontSize: '22px',
  lineHeight: '30px',
  marginLeft: '20px',
});

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    margin: 0, 
    "&:before": {
      display: "none",
    },
    '&$expanded': {
      margin: 0, // Ensure no margin when expanded
    },
  },
  expanded: {},
})(MuiAccordion);


const AccordionSummary = withStyles({
  root: {
    backgroundColor: colors.white,
    borderBottom: `1px solid ${colors.cloudy_blue_50}`,
    paddingRight: '28px',
    minHeight: '60px',
    "&$expanded": {
      minHeight: '60px',
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    padding: 0,
    width: '100%',
  },
})(MuiAccordionDetails);

const ListContainer = styled('div')({
  width: '100%',
});

function CompletedMilestonesAccordion (props) {
  const { visible, completedMilestones, markMilestoneAsComplete } = props;
  const [expanded, setExpanded] = useState(false);

  const handleExpanded = () => {
    setExpanded(prev => !prev);
  };

  if (!visible) {
    return null;
  }

  return (
    <Accordion
      square
      expanded={expanded}
      onChange={() => handleExpanded('completed')}
    >
      <AccordionSummary
        expandIcon={<ExpandIcon />}
      >
        <AccordionHeader>Completed</AccordionHeader>
      </AccordionSummary>
      <AccordionDetails>
        <ListContainer>
          {
            completedMilestones.map(milestone => {
              return (
                <Milestone
                  completed
                  key={milestone.id}
                  milestone={milestone}
                  markMilestoneAsComplete={markMilestoneAsComplete}
                />
              );
            })
          }
        </ListContainer>
      </AccordionDetails>
    </Accordion>
  );
}

export default CompletedMilestonesAccordion;

CompletedMilestonesAccordion.propTypes = {
  visible: PropTypes.bool,
  completedMilestones: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    status: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  })),
  markMilestoneAsComplete: PropTypes.func,
};
