import React, { useEffect, useState, useCallback } from 'react';
import { Box, Typography, CircularProgress, Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import { validateEmail } from '../../util/trainerClientUtil';
import {hasSpecialCharactersOrNumbers, name as validateName} from '../../util/validate';
import nasmApi from '../../api/endpoints';
import { selectClient } from '../../reducers/selectedClientReducer';

import MacaroniButton from '../Buttons/MacaroniButton';
import OvalButton from '../Buttons/OvalButton';
import UnderlineTextInput from '../Inputs/UnderlineTextInput';
import ResendInviteDialog from './ResendInviteDialog';
import { colors } from '../../styles';
import checkMark from '../../resources/interaction-toggle-checkmark-on-big.svg';
import closeCircleWhite from '../../resources/close_circle_white.svg';
import placeholderIcon from '../../resources/user-dashboard-client-profile-with-no-photo@2x.png';
import { FEATURE_FLAGS } from '../../constants';

const CREATE_USER_FLOW = {
  CREATE: 'CREATE',
  INVITE: 'INVITE',
  ERROR: 'ERROR',
};

const useStyles = makeStyles({
  root: {
    padding: '28px',
  },
  statusCont: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '460px',
  },
  statusMsg: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '36px',
  },
  topSection: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  topBtnCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '210px',
  },
  headerAndSubheader: {
    margin: '32px 0',
  },
  headerTitle: {
    fontSize: '40px',
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: '8px',
  },
  subheader: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    textAlign: 'center',
  },
  textFieldCont: {
    margin: '16px 0',
  },
  inputLabel: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    color: colors.steel,
  },
  topSectionClubConnect: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  topProfileCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  topBtnContClubConnect: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '180px',
  },
  profileImg: {
    height: '48px',
    width: '48px',
    borderRadius: '48px',
    border: '1px solid',
    borderColor: colors.grey_text_color,
    objectFit: 'cover',
  },
  profileListName: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '17px',
    fontWeight: 700,
    marginLeft: '15px',
  },
  formClose: {
    height: '38px',
    width: '38px',
    objectFit: 'cover',
    cursor: 'pointer',
  },
  sendInviteTopProfileCont: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 25,
    paddingLeft: 25,
  },
});

function AddNewClientForm (props) {
  const { visible, onClose, newInviteSent, onNewInviteSent } = props;
  const selectedProfile = useSelector(state => state?.clubConnect?.selectedProfile);
  const dispatch = useDispatch();
  const classes = useStyles();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [invalidEmailError, setInvalidEmailError] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);

  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError ] = useState(false);

  const [loading, setLoading] = useState(false);

  const [openResendInvite, setOpenResendInvite] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [inviteMessage, setInviteMessage] = useState('');

  let firstNameErrorText = '';
  if (firstName.length === 0) {
    firstNameErrorText = 'First name is required';
  } else if (hasSpecialCharactersOrNumbers(firstName)) {
    firstNameErrorText = 'First name should not contain special symbols or numbers';
  }

  let lastNameErrorText = '';
  if (lastName.length === 0) {
    lastNameErrorText = 'Last name is required';
  } else if(hasSpecialCharactersOrNumbers(lastName)) {
    lastNameErrorText = 'Last name should not contain special symbols or numbers';
  }

  const handleNewClient = useCallback(() => {
    const user = {
      email,
      first_name: firstName,
      last_name: lastName,
    };
    if (selectedProfile?.ClubId) {
      user.club_id = selectedProfile?.ClubId;
      if (FEATURE_FLAGS.CLUB_CONNECT_MULTI_LOCATION_ENABLED && selectedProfile?.Locations?.Id) {
        user.location_id = selectedProfile?.Locations?.Id;
      }
    }
    nasmApi.trainer.createClientUser(user)
      .then((result) => {
        onNewInviteSent(false);
        dispatch(selectClient(result.result));
      })
      .catch((error) => {
        alert(error.data.message);
        onClose();
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, email, firstName, lastName, onClose, onNewInviteSent, selectedProfile]);

  const sendInvite = useCallback(() => {
    if (emailError || invalidEmailError || firstNameError || lastNameError) {
      setFormSubmitting(false);
      return;
    }
    setLoading(true);

    // Verify that the email isn't already associated
    // with an existing client
    validateEmail(email, selectedProfile?.ClubId, selectedProfile?.Locations?.Id)
      .then((response) => {
        if (response.status === CREATE_USER_FLOW.CREATE) {
          handleNewClient();
        } else if (response.status === CREATE_USER_FLOW.INVITE) {
          setInviteMessage(response?.message);
          setOpenResendInvite(true);
        } else if (response.status === CREATE_USER_FLOW.ERROR) {
          setErrorMessage(response?.message);
        }
      })
      .catch((error) => {
        alert(error.message);
      })
      .finally(() => {
        setLoading(false);
        setFormSubmitting(false);
      });
  }, [email, emailError, invalidEmailError, firstNameError, lastNameError, handleNewClient, selectedProfile]);

  useEffect(() => {
    if (!formSubmitting) return;
    sendInvite();
  }, [email, firstName, lastName, formSubmitting, sendInvite]);

  // Resend invite to clients that are already registered
  const handleExistingClient = () => {
    const data = {
      email,
    };
    if (selectedProfile?.ClubId) {
      data.club_id = selectedProfile?.ClubId;
      if (FEATURE_FLAGS.CLUB_CONNECT_MULTI_LOCATION_ENABLED && selectedProfile?.Locations?.Id) {
        data.location_id = selectedProfile?.Locations?.Id;
      }
    }
    nasmApi.trainer.inviteExistingClient(data).then((result) => {
      onNewInviteSent(true);
      dispatch(selectClient(result));
    }).catch((error) => {
      alert(error.data.message);
    }).finally(() => {
      setLoading(false);
      setOpenResendInvite(false);
    });
  };

  const onClickSendInvite = () => {
    setFormSubmitting(true);
    const emailRegex = /\S+@\S+\.\S{2,}/;

    // Validating form fields
    if (!email) {
      setEmailError(true);
    }
    if (!firstName || !validateName(firstName) || hasSpecialCharactersOrNumbers(firstName)) {
      setFirstNameError(true);
    }
    if (!lastName || !validateName(lastName) || hasSpecialCharactersOrNumbers(lastName)) {
      setLastNameError(true);
    }
    if (!email.match(emailRegex)) {
      setInvalidEmailError(true);
    }
  };

  const onChangeEmail = (value) => {
    setEmailError(false);
    setInvalidEmailError(false);
    if (!value) {
      setEmailError(true);
    }
    setEmail(value);
  };

  const onChangeFirstName = (value) => {
    setFirstNameError(false);
    if (!value || !validateName(value) || hasSpecialCharactersOrNumbers(value)) {
      setFirstNameError(true);
    }

    if (value && value.length > 0) {
      setFirstName(value.trim());
    } else {
      setFirstName(value);
    }
  };

  const onChangeLastName = (value) => {
    setLastNameError(false);
    if (!value || !validateName(value) || hasSpecialCharactersOrNumbers(value)) {
      setLastNameError(true);
    }

    if (value && value.length > 0) {
      setLastName(value.trim());
    } else {
      setLastName(value);
    }
  };

  const renderProfile = () => {
    return (
      <>
        {selectedProfile?.ClubId ?
          <Box className={classes.sendInviteTopProfileCont}>
            <img
              className={classes.profileImg}
              src={selectedProfile?.ClubLogoUrl || placeholderIcon}
              alt='Profile'
              onError={(e) => {
                e.target.src = placeholderIcon;
              }}
            />
            <Typography className={classes.profileListName}>
              {`${selectedProfile?.ClubName}`}
            </Typography>
          </Box> : <></>
        }
      </>
    );
  };

  if (!visible) return null;

  if (loading) {
    return (
      <>
        {renderProfile()}
        <Box className={classes.statusCont}>
          <Typography className={classes.statusMsg}>Sending...</Typography>
          <CircularProgress />
        </Box>
      </>
    );
  }

  if (newInviteSent) {
    return (
      <>
        {renderProfile()}
        <Box className={classes.statusCont}>
          <Typography className={classes.statusMsg}>Invite Sent!</Typography>
          <img src={checkMark} alt='check' />
        </Box>
      </>
    );
  }

  return (
    <>
      <Fade in={visible} mountOnEnter unmountOnExit timeout={500}>
        <Box className={classes.root}>
          {selectedProfile?.ClubId ? (
            <Box className={classes.topSectionClubConnect}>
              <Box className={classes.topProfileCont}>
                <img
                  className={classes.profileImg}
                  src={selectedProfile?.ClubLogoUrl || placeholderIcon}
                  alt='Profile'
                  onError={(e) => {
                    e.target.src = placeholderIcon;
                  }}
                />
                <Typography className={classes.profileListName}>{`${selectedProfile?.ClubName}`}</Typography>
              </Box>
              <Box className={classes.topBtnContClubConnect}>
                <MacaroniButton width='127px' onClick={onClickSendInvite}>Send Invite</MacaroniButton>
                <img
                  className={classes.formClose}
                  src={closeCircleWhite}
                  alt='close'
                  onError={(e) => {
                    e.target.src = closeCircleWhite;
                  }}
                  onClick={onClose}
                />
              </Box>
            </Box>
          ) : (
            <Box className={classes.topSection}>
              <Box className={classes.topBtnCont}>
                <MacaroniButton width='127px' onClick={onClickSendInvite}>Send Invite</MacaroniButton>
                <OvalButton onClick={onClose}>Close</OvalButton>
              </Box>
            </Box>
          )}
          <Box className={classes.headerAndSubheader}>
            <Typography className={classes.headerTitle}>Add New Client</Typography>
            <Typography className={classes.subheader}>
              Send an invite to download EDGE from the app store.
              Access to the web app is not supported at this time for Clients.
            </Typography>
          </Box>
          <Box>
            <Box className={classes.textFieldCont}>
              <Typography className={classes.inputLabel}>Email</Typography>
              <UnderlineTextInput
                value={email}
                placeholder=''
                onNameChanged={(e) => {
                  onChangeEmail(e.target.value);
                  setErrorMessage('');
                }}
                showError={emailError || invalidEmailError || errorMessage}
                errorColor={errorMessage.length ? colors.red : colors.yellow_button_style}
                errorText={errorMessage.length ? errorMessage
                  : emailError ? 'Email is required' : 'Invalid email format'}
              />
            </Box>
            <Box className={classes.textFieldCont}>
              <Typography className={classes.inputLabel}>First Name</Typography>
              <UnderlineTextInput
                value={firstName}
                placeholder=''
                onNameChanged={(e) => onChangeFirstName(e.target.value)}
                showError={firstNameError}
                errorText={firstNameErrorText}
              />
            </Box>
            <Box className={classes.textFieldCont}>
              <Typography className={classes.inputLabel}>Last Name</Typography>
              <UnderlineTextInput
                value={lastName}
                placeholder=''
                onNameChanged={(e) => onChangeLastName(e.target.value)}
                showError={lastNameError}
                errorText={lastNameErrorText}
              />
            </Box>
          </Box>
        </Box>
      </Fade>
      <ResendInviteDialog
        loading={loading}
        open={openResendInvite}
        message={inviteMessage}
        onClose={() => setOpenResendInvite(false)}
        onResend={handleExistingClient}
        inviteSent={newInviteSent}
      />
    </>
  );
}

export default AddNewClientForm;
